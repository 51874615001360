var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "buttons"
  }, [_c('button', {
    style: {
      width: _vm.myWidth + 'px',
      background: _vm.color
    },
    attrs: {
      "disabled": _vm.disabled
    }
  }, [_vm._v(_vm._s(_vm.text)), _vm.showLoader ? _c('div', {
    staticClass: "lds-ring"
  }, [_c('div'), _c('div'), _c('div'), _c('div')]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }