<template>
  <div class="buttons">
    <button :disabled="disabled" :style="{ width: myWidth + 'px' , background : color }">{{text}}<div v-if="showLoader" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div></button>
  </div>
</template>
<script>
export default {
  name: 'LoginButtonWithLoader',
  props: {
    showLoader: false,
    text: '',
    color: {
      type: String,
      default: '#297874'
    },
    myWidth: {
      type: Number,
      default: 280
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
}
</script>
<style scoped>
.buttons button {
  position: relative;
  height: 50px;
  border-radius: 5px;
  appearance: none;
  outline: none;
  border: none;
  color: white;
  font-weight: 500;
}
.buttons button:disabled {
  opacity: .7;
}

.lds-ring {
  left: 35px;
  bottom: 10px;
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 30px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 6px solid #CBA548;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #CBA548 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
