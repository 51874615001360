<template>
    <div class="events px-3">
      <div class="my-container">
        <img :src="eventStyle.logo" alt="" srcset="">
        <div class="content">
          <div class="inputs">
            <div class="my-4">
              <p>أدخل رمز التأكيد لإتمام الدفع</p>
              <div class="otp-input d-flex align-items-center justify-content-between">
                <input v-for="(index) in 6" :key="index" maxlength="1" type="text" name="" v-model="OTPCodeField[index-1]" ref="input" @input="foucsTo(index)">
              </div>
            </div>
          </div>
          <div class="buttons mt-5">
            <ButtonWithLoader @click.native="inputValidation()" :disabled="btnLoader" :showLoader="btnLoader" :color="eventStyle.btnColor" text="تأكيد الحجز"/>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import toasted from '../../../shared/utilities/toasted'
import eventsRepository from "../../repositories/eventsRepository.js";
import ButtonWithLoader from '../../../website/website-components/events/ButtonWithLoader.vue';
export default {
    name: 'SendOTPPage',
    components: { ButtonWithLoader },
    metaInfo: {
      title: 'الحفلات',
    },
    data: () => ({
        btnLoader: false,
        OTPCodeField: [],
        OTPCode: null,
        eventStyle: null
    }),
    methods: {
      inputValidation() {
            if (this.OTPCodeField.length != 6 || this.OTPCodeField.some(x => x == null || x == '')) {
                toasted.failed('الرجاء إدخال الكود بالطريقة الصحيحة');
            } else {
                this.confirmReservation()
            }
      },
      async confirmReservation() {
        this.btnLoader = true;
        let ReservationDetails = JSON.parse(localStorage.getItem('ReservationDetails'))
        try {
          const Reservation = await eventsRepository.confirmReservation(ReservationDetails?.data.order.id,this.OTPCode)
          toasted.success('تم الحجز بنجاح');
          this.$router.push('/events')
          this.btnLoader = false           
        } catch (error) {
          this.btnLoader = false        
        }
      },
      foucsTo(x){
          if (this.OTPCodeField[x-1] != '') {
            if (x != 6) {
              this.$refs.input[x].focus()
            }
          }
          let element = ''
          for (let index = 0; index < this.OTPCodeField.length; index++) {
            element = element + this.OTPCodeField[index];
          }
          this.OTPCode = element
      },
    },
    created(){
      this.eventStyle = JSON.parse(localStorage.getItem('eventStyle')) ?? { logo: null, btnColor: null }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
.events {
  font-family: 'Tajawal', sans-serif;
  max-width: 425px;
  min-height: 100vh;
  margin: 0px auto;
  padding-bottom: 50px;
}
.events .my-container{
  padding-top: 40px;
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background: url("../../../../../public/assets/images/website/events/background.png") no-repeat bottom ; */
  /* background-size: 280px auto */
}
.events .my-container img{
  max-width: 240px
}
.events .my-container .inputs .otp-input{
  direction: ltr;
}
.events .my-container .inputs input{
  font-size: 17px;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  border: 1px solid #A78853;
  outline: none;
  padding: 0px 5px;
  text-align: center;
}
.events .my-container .inputs input:focus{
  border: 3px solid #A78853;
}
.events .my-container .inputs p{
  color: #936C22;
  font-weight: 500;
  margin-bottom: 10px;
}
</style>