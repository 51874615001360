import {httpClient} from "../../shared/services";
import {apiBaseUrl} from '@/environment/environment'

export default {

  getEventId(){

    const url = apiBaseUrl + 'configuration/findByKey/event_id'
    return httpClient.get(url)
      .then(response => response.data.data)
      .catch(error => console.log(error))

  },

  getEventDetails(id) {
    
    const url = apiBaseUrl + 'event/event-definition/'+ id +'/public-events'
    return httpClient.get(url)
      .then(response => response.data.data[0])
      .catch(error => console.log(error))
  },

  bookingChairs(payload) {
    const url = apiBaseUrl + 'event/event-users/validate-booking'
    return httpClient.post(url,payload)
      .then(response => response.data)
      .catch(error => { console.log(error); return Promise.reject(error);})
  },

  confirmReservation(order_id,otp_code) {
    const url = apiBaseUrl + 'event/event-users/book/' + order_id + '?otp=' + otp_code
    return httpClient.get(url)
      .then(response => response)
      .catch(error => { console.log(error); return Promise.reject(error);})
  },

}
