var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "events px-3"
  }, [_c('div', {
    staticClass: "my-container"
  }, [_c('img', {
    attrs: {
      "src": _vm.eventStyle.logo,
      "alt": "",
      "srcset": ""
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "inputs"
  }, [_c('div', {
    staticClass: "my-4"
  }, [_c('p', [_vm._v("أدخل رمز التأكيد لإتمام الدفع")]), _c('div', {
    staticClass: "otp-input d-flex align-items-center justify-content-between"
  }, _vm._l(6, function (index) {
    return _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.OTPCodeField[index - 1],
        expression: "OTPCodeField[index-1]"
      }],
      key: index,
      ref: "input",
      refInFor: true,
      attrs: {
        "maxlength": "1",
        "type": "text",
        "name": ""
      },
      domProps: {
        "value": _vm.OTPCodeField[index - 1]
      },
      on: {
        "input": [function ($event) {
          if ($event.target.composing) { return; }
          _vm.$set(_vm.OTPCodeField, index - 1, $event.target.value);
        }, function ($event) {
          return _vm.foucsTo(index);
        }]
      }
    });
  }), 0)])]), _c('div', {
    staticClass: "buttons mt-5"
  }, [_c('ButtonWithLoader', {
    attrs: {
      "disabled": _vm.btnLoader,
      "showLoader": _vm.btnLoader,
      "color": _vm.eventStyle.btnColor,
      "text": "تأكيد الحجز"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.inputValidation();
      }
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }